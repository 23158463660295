import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <title>Not found</title>
      <div className="box white">
        <h1>Page not found</h1>
        <p>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn't find what you were looking for.
          <br />
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
